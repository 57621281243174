<template>
    <div class="content pt-0">
        <section class="tyre-bg">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-3">
                        <div class="title-small mb-4 text-center">
                            Bejelentkezés
                        </div>
                        <p class="text-center">
                            Ha rendelkezel felhasználó fiókkal, kérlek
                            jelentkezz be.
                        </p>
                        <form v-on:submit.prevent="doLogin">
                            <div class="my-4">
                                <label>Email cím</label>
                                <input
                                    type="email"
                                    v-model="form.email"
                                    name="email"
                                    class="form-control form-underlined"
                                />
                            </div>
                            <div class="my-4">
                                <label>Jelszó</label>
                                <input
                                    type="password"
                                    v-model="form.password"
                                    name="password"
                                    class="form-control form-underlined"
                                />
                            </div>

                            <button type="submit" class="btn btn-primary w-100">
                                Bejelentkezés
                            </button>

                            <div class="row my-4">
                                <div class="col-md-6">
                                    <router-link :to="{ name: 'Registration' }"
                                        >Vissza a regisztrációhoz</router-link
                                    >
                                </div>
                                <div class="col-md-6 text-end">
                                    <router-link
                                        :to="{ name: 'ForgetPassword' }"
                                        >Elfelejtett jelszó</router-link
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            form: {
                email: null,
                password: null
            }
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    methods: {
        doLogin() {
            this.$http.post(`${this.url}/login`, this.form).then(response => {
                $cookies.set("id_token", response.data.token, 86400);
                $cookies.set("user", response.data, 86400);

                document.location.href = "/";
            });
        }
    }
};
</script>

<style scoped></style>
